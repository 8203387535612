'use client'; // Error components must be Client Components

import supabase from '@/lib/supabase.client';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

import { useRouter } from 'next/navigation';
import { useLogger } from 'next-axiom';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();
  const log = useLogger();
  log.error('User saw error: ', error);

  return (
    <div className='container flex h-screen w-screen flex-col items-center justify-center'>
      <div className='mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]'>
        <Card>
          <CardHeader>
            <CardTitle>Sorry, we ran into an issue</CardTitle>
            <CardDescription>
              Please click the button below to try again. If the problem
              persists, please reach out.
            </CardDescription>
          </CardHeader>
          <CardFooter className='gap-4'>
            <Button
              onClick={
                // Attempt to recover by trying to re-render the segment
                () => {
                  reset();
                  router.refresh();
                  location.reload();
                }
              }
            >
              Try again
            </Button>
            <Button
              onClick={async () => {
                await supabase.auth.signOut();
                router.push('/');
              }}
              variant={'secondary'}
            >
              Sign out
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
